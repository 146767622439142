<template>
    <div>
      <b-overlay :show="show" rounded="sm" no-fade>
        <b-card-code title="Informasi Data Penggajian">
  
          <!-- <b-row class="mt-2 mb-2" align="end">
            <b-col md="2">
              <b-form-input
                id="name"
                v-model="owner_name"
                placeholder="Cari Nama Pemilik"
                @input="changeStatus()"
              />
            </b-col>
  
            <b-col md="2" align="start">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="resetFilter()"
              >
                Reset
              </b-button>
            </b-col>
          </b-row> -->
  
          <!-- table -->
          <vue-good-table
            mode="remote"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: false,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
            }"
            @on-sort-change="onSortChange"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'created_date'">
                {{ props.row.created_date.substring(0, 10) }}
              </span>
  
              <span v-else-if="props.column.field === 'payment_status'">
                <span v-if="props.row.payment_status === 'LUNAS'">
                  <b-badge variant="success"> LUNAS </b-badge>
                </span>
  
                <span v-if="props.row.payment_status === 'TEMPO'">
                  <b-badge variant="warning"> TEMPO </b-badge>
                </span>
  
                <span v-if="props.row.payment_status === 'BOOKING'">
                  <b-badge variant="info"> BOOKING </b-badge>
                </span>
  
                <span v-if="props.row.payment_status === 'TERSEDIA'">
                  <b-badge variant="primary"> TERSEDIA </b-badge>
                </span>
              </span>
  
              <span v-else-if="props.column.field === 'payroll_date'">
                {{ props.row.payroll_date.substring(0, 10) }}
              </span>
  
              <span v-else-if="props.column.field === 'length'">
                {{ props.row.length }} x {{ props.row.width }}
              </span>
  
              <span v-else-if="props.column.field === 'lineNumber'">
                {{ paging.page * 10 + (props.index + 1) }}.
              </span>
  
              <span v-else-if="props.column.field === 'total_base_salary'">
                Rp.
                {{
                  props.row.total_base_salary
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                }}
              </span>
  
              <span v-else-if="props.column.field === 'total_reward'">
                Rp.
                {{
                  props.row.total_reward
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                }}
              </span>
  
              <span v-else-if="props.column.field === 'total_all'">
                Rp.
                {{
                  props.row.total_all
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                }}
              </span>
  
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
  
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="downloadFilter(props)"
                >
                  <span class="align-middle">Download</span>
                </b-button>
                
              </span>
  
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
  
            <!-- pagination -->
  
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="paging.size"
                    :options="paging.perPageOptions"
                    class="mx-1"
                    @input="handlePageChange"
                  />
                  <span class="text-nowrap">
                    of {{ paging.total_elements }} entries
                  </span>
                </div>
  
                <div>
                  <b-pagination
                    :value="paging.page + 1"
                    :total-rows="paging.total_elements"
                    :per-page="paging.size"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="handleChangePage"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
  
        </b-card-code>
      </b-overlay>
    </div>
  </template>
  
  <script>
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import axios from "@axios";
  import vSelect from "vue-select";
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    BFormFile,
    BOverlay,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardText,
    BCardBody,
    BDropdownItem,
    BDropdown,
    VBTooltip,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import { VueGoodTable } from "vue-good-table";
  import store from "@/store/index";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import flatPickr from "vue-flatpickr-component";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, email } from "@validations";
  
  export default {
    components: {
      BCardCode,
      VBTooltip,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BForm,
      BButton,
      BPagination,
      VueGoodTable,
      BFormFile,
      BOverlay,
      vSelect,
      BFormSelect,
      BBadge,
      BFormTextarea,
      BMedia,
      BAvatar,
      BMediaBody,
      BMediaAside,
      BAvatarGroup,
      BImg,
      BInputGroupPrepend,
      BCard,
      BCardBody,
      BCardText,
      BDropdownItem,
      BDropdown,
      flatPickr,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {
        //here
        file: null,
  
        required,
        email,
  
        log: [],
        dir: false,
        pages: ["5", "10", "20", "30"],
  
        paging: {
          page: 0,
          total_pages: 0,
          size: 10,
          total_elements: 0,
          perPageOptions: [10, 25, 50, 100],
        },
  
        columns: [
          {
            label: "No",
            field: "lineNumber",
            thClass: "text-center",
            tdClass: "text-center",
            width: "70px",
          },
          {
            label: "Tanggal Dibuat",
            field: "payroll_date",
            thClass: "text-center",
          },
          {
            label: "Periode",
            field: "payroll_periods",
            thClass: "text-center",
          },
          {
            label: "Total Gaji Pokok",
            field: "total_base_salary",
            thClass: "text-center",
          },
          {
            label: "Total Reward",
            field: "total_reward",
            thClass: "text-center",
          },
          {
            label: "Total",
            field: "total_all",
            thClass: "text-center",
          },
          {
            label: "Aksi",
            field: "action",
            thClass: "text-center",
            tdClass: "text-center",
            width: "200px",
          },
        ],
        rows: [],
  
        tanggal_filter: "",
  
        configs: {
          mode: "range",
          allowInput: {
            allowInput: true,
            dateFormat: "Y-m-d",
          },
        },
  
        formDisabled: {
          purchase_date: "",
          buyer_name: "",
          facility: "",
          payment_status: "",
          kavling_name: "",
        },
  
        formSubmit: {
          name: "",
          kavling_id: "",
          price: "",
          length: "",
          width: "",
          is_booked: false,
          note: "",
          facility: "",
        },
  
        kavlingOptions: [],
        marketingOptions: [],
        paymentStatusBlockOptions: [],
  
        // old delete
        searchTerm: "",
  
        name: "",
        kavling_id: "",
        marketing_id: "",
        payment_status: "",
        buyer_name: "",
        facility: "",
  
        sort: "",
  
        create_time: "",
  
        show: false,
        aksi: "",
        id_kavling: "",
  
        nama: "",

        payroll_date: "",
  
        selected: null,
      };
    },
  
    beforeMount() {
      this.getData(this.paging.size, this.paging.page);
      this.getKavling();
      this.getMarketing();
      this.getPaymenStatusBlock();
    },
  
    computed: {
      direction() {
        if (store.state.appConfig.isRTL) {
          this.dir = true;
          return this.dir;
        }
        this.dir = false;
        return this.dir;
      },
    },
  
    created() {
      //here
    },
  
    methods: {
      getData(size, page) {
        this.show = true;
  
        let search0 = "";
        if (this.payroll_date) {
          search0 = "&payroll_date=" + this.payroll_date;
        } else {
          search0;
        }
  
        return new Promise(() => {
          axios
            .get(
              "v1/payroll/all?" +
                "page=" +
                page +
                "&size=" +
                size +
                "&sort_by=create_time&sort_dir=-1" 
                // +
                // search0
            )
            .then((res) => {
              this.rows = res.data.content;
  
              this.paging.page = res.data.page;
              this.paging.size = res.data.size;
              this.paging.total_elements = res.data.total_elements;
              this.paging.total_pages = res.data.total_pages;
  
              this.show = false;
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
              }
              this.show = false;
            });
        });
      },
  
      downloadFilter(props) {
        window.open(
          "https://api-bsgproperti.mrizkyff.com/v1/payroll/download/" + props.row._id
        );
      },
  
      changeStatus() {
        const page = (this.paging.page = 0);
        this.getData(this.paging.size, page);
      },
  
      getKavling() {
        return new Promise(() => {
          axios.get("v1/kavling/combo").then((res) => {
            this.kavlingOptions = res.data;
          });
        });
      },
  
      getMarketing() {
        return new Promise(() => {
          axios.get("v1/employee/combo/marketing").then((res) => {
            this.marketingOptions = res.data;
          });
        });
      },
  
      getPaymenStatusBlock() {
        return new Promise(() => {
          axios.get("v1/block/combo/payment_status").then((res) => {
            this.paymentStatusBlockOptions = res.data;
          });
        });
      },
  
      pilihKavling(event) {
        this.formSubmit.kavling_id = event;
      },
  
      resetFilter() {
        this.kavling_id = "";
        this.marketing_id = "";
        this.payment_status = "";
        this.block_name = "";
        this.owner_name = "";
        const page = (this.paging.page = 0);
        this.getData(this.paging.size, page);
      },
  
      handleSearch(searching) {
        const page = (this.paging.page = 0);
        this.getData(this.paging.size, page);
      },
      handleChangePage(value) {
        const page = value - 1;
        this.getData(this.paging.size, page);
      },
      handlePageChange(active) {
        const page = this.paging.page;
        this.getData(this.paging.size, page);
      },
      onSortChange(params) {
        this.sort = `${params[0].field}`;
        this.getData(this.paging.size, this.paging.page);
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
  